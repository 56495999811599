/*
COLORNAME
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 21, 2022
UPDATE DATE: May 21, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ColorName | Find your color name!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

%anim{
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

$normalColor: #AAA;
$holidayColor: #D10000;
$longColor: #4FA83D;
$todayColor: #002FA7;

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  @extend %anim;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  @extend %anim;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.react-select{
  .react-select__control{
    border: 1px solid #CCC;
    border-radius: 5px;
    min-height: 40px;
    box-shadow: none;
    @extend %anim;

    &.react-select__control--is-focused,
    &:hover{
      border: 1px solid #999;

      .react-select__indicators{
        background: #999;
      }
    }

    .react-select__value-container{
      padding: 0 15px;

      .react-select__single-value{
        margin: 2px 0 0 0;
        font-size: 14px;
        line-height: 18px;
      }

      .react-select__input-container{
        margin: 0;
        padding: 0;

        input{
          font-size: 14px !important;
        }
      }
    }

    .react-select__indicators{
      cursor: pointer;
      background: #CCC;
      border-radius: 0 4px 4px 0;
      @extend %anim;

      .react-select__indicator-separator{
        display: none;
        width: 0;
      }

      .react-select__indicator{
        color: #FFF;
        padding: 10px;

        svg{
          width: 18px;
          height: 18px;
          @extend %anim;
        }
      }
    }

    &.react-select__control--menu-is-open{
      border-radius: 5px 5px 0 0;

      .react-select__indicators{
        border-radius: 0 4px 0 0;

        svg{
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.react-select__menu-portal{
  z-index: 4 !important;

  .react-select__menu{
    margin: 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid #999;
    border-top: none;
    box-shadow: 0 20px 20px -10px rgba(0,0,0,.1);
    overflow: hidden;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        cursor: pointer;
        color: #666;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 18px;
        @extend %anim;

        &.react-select__option--is-focused{
          background: #EEE;
        }

        &.react-select__option--is-selected{
          background: #666;
          color: #FFF;
        }
      }
    }
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;

      > div{
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;

        > span{
          display: block;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
        }
      }
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > ul{
    margin: -5px;
    overflow: auto;

    > li{
      position: relative;
      padding: 5px;
      float: left;
      box-sizing: border-box;

      &:nth-child(1){
        width: 35%;

        > a{
          float: left;
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;

          > svg{
            display: block;
            width: 164px;
            height: 40px;
          }
        }

        .today{
          margin-left: 174px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          // border: 1px solid #CCC;
          // background: #FFF;
          background: #EEE;
          padding: 0 10px;
          border-radius: 5px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @extend %anim;

          &:hover{
            background: #DDD;
            color: #888;

            > strong{
              color: #555;
            }
          }

          > strong{
            color: #666;
          }
        }
      }

      &:nth-child(2){
        width: 15%;
      }

      &:nth-child(3){
        width: 10%;
      }

      &:nth-child(4){
        width: 10%;
      }

      &:nth-child(5){
        width: 10%;
      }

      &:nth-child(6){
        width: 10%;
      }

      &:nth-child(7){
        width: 10%;
      }
      
      > label{
        background: #fff;
        font-size: 11px;
        left: 16px;
        line-height: 11px;
        padding: 0 5px;
        position: absolute;
        top: 1px;
        z-index: 1;
      }

      > button{
        background: #666;
        color: #FFF;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover{
          background: #333;
        }

        span{
          display: block;
          font-size: 9px;
          font-weight: normal;
          margin: 0 0 1px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > svg{
          display: inline-block;
          vertical-align: middle;
          margin: -3px 5px 0 0;
          width: 14px;
          height: 14px;
        }
      }
    }

    &.more{
      > li{
        &:nth-child(1){
          width: 40%;
        }

        &:nth-child(3){
          width: 15%;
        }
      }
    }
  }
}

.pad-top{
  height: 70px;
}

.loading{
  text-align: center;
  padding: 100px 20px;

  > ul{
    width: 48px;
    height: 48px;
    margin: 0 auto;
    padding-bottom: 5px;
    // overflow: auto;

    > li{
      position: relative;
      float: left;
      width: 33.33%;
      height: 16px;

      > div{
        width: 12px;
        height: 12px;
        background: #CCC;
        border-radius: 3px;
        margin: 0 auto;
        animation: loading 1s infinite ease-in-out;
      }

      &:nth-child(2){
        > div{
          animation-delay: 0.1s;
        }
      }

      &:nth-child(3){
        > div{
          animation-delay: 0.2s;
        }
      }

      &:nth-child(4){
        > div{
          animation-delay: 0.3s;
        }
      }

      &:nth-child(5){
        > div{
          animation-delay: 0.4s;
        }
      }

      &:nth-child(6){
        > div{
          animation-delay: 0.5s;
        }
      }

      &:nth-child(7){
        > div{
          animation-delay: 0.6s;
        }
      }

      &:nth-child(8){
        > div{
          animation-delay: 0.7s;
        }
      }

      &:nth-child(9){
        > div{
          animation-delay: 0.8s;
        }
      }
    }
  }
}

@keyframes loading{
  0% { opacity: .25; transform: scale(1, 1); }
  50% { opacity: 1; transform: scale(1.2, 1.2); }
  100% { opacity: .25; transform: scale(1, 1); }
}

footer{
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }

    // > img{
    //   display: inline-block;
    //   vertical-align: middle;
    //   width: 14px;
    //   height: 14px;
    //   margin: -3px 0 0 0;
    // }
  }

  > div{
    margin: 2.5px 0 0 0;
    font-size: 10px;
    line-height: 14px;
  }
}

ul.calendar{
  clear: both;
  padding: 10px;

  > li{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;

    &.year{
      > div{
        text-align: center;
        color: #666;
        font-size: 140px;
        line-height: 144px;
        font-weight: 100;
      }

      > ul.insight{
        margin: -5px -15px;
        padding-bottom: 15px;
        text-align: center;

        > li{
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          line-height: 18px;
          padding: 5px 15px;
          text-align: center;

          > strong{
            display: block;
            color: #666;
            font-size: 16px;
            line-height: 20px;
            margin-top: 2px;

            > div{
              width: 14px;
              height: 14px;
              display: inline-block;
              vertical-align: middle;
              clear: both;
              margin: -3px 5px 0 0;

              &.grey{
                background: $normalColor;
              }

              &.red{
                background: $holidayColor;
              }

              &.green{
                background: $longColor;
              }
            }

            > span{
              font-weight: normal;
              color: #999;
              font-size: 12px;
            }
          }
        }
      }

      > ul.meter{
        margin: 0 0 5px 0;

        > li{
          position: relative;
          display: inline-block;
          vertical-align: top;
          height: 35px;
          color: #FFF;
          font-weight: bold;
          font-size: 12px;
          line-height: 10px;
          padding: 10px 0;
          box-sizing: border-box;
          // overflow: hidden;
          @extend %anim;
  
          > span{
            position: absolute;
            margin: auto;
            text-align: center;
            top: 50%;
            transform: translate(0, -50%);
          }
  
          &:nth-child(1){
            background: $normalColor;
  
            > span{
              left: 15px;
            }
          }
  
          &:nth-child(2){
            background: $longColor;
            z-index: 1;
  
            > span{
              // left: 0;
              // right: 0;
              left: 50%;
              transform: translate(-50%, -50%);
              background: rgba($longColor, .75);
              padding: 4px;
            }
          }
  
          &:nth-child(3){
            background: $holidayColor;
  
            > span{
              right: 15px;
            }
          }
        }
      }
    }

    > div{
      // position: relative;
      
      > div{
        > .head{
          &.sticky{
            position: fixed;
            top: 70px;
            left: 0;
            right: 0;
            padding: 0 20px;
            background: rgba(255, 255, 255, .95);
            z-index: 3;
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);
            // position: -webkit-sticky;
            // position: sticky;
            // top: 0;

            > ul.insight{
              top: 0;
              right: 20px;
            }
          }

          &.was-sticky{
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 9px;
            padding: 0 10px;
            background: rgba(255, 255, 255, .95);
            z-index: 2;
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            box-shadow: none;

            > ul.insight{
              top: 0;
              right: 10px;
            }
          }

          // &.was-sticky{
          //   position: absolute;
          //   bottom: 0px;
          //   left: 0;
          //   right: 0;
            // padding: 0 20px;
            // background: rgba(255, 255, 255, .95);
            // z-index: 3;
            // backdrop-filter: blur(3px);
            // -webkit-backdrop-filter: blur(3px);
            // box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

            // > ul.insight{
            //   top: 0;
            //   right: 20px;
            // }
          // }

          > .name{
            font-size: 32px;
            line-height: 36px;
            color: #666;
            padding-bottom: 10px;
          }
      
          > ul.insight{
            position: absolute;
            right: 10px;
            top: 10px;
            text-align: right;
            margin: -5px -10px;
      
            > li{
              display: inline-block;
              vertical-align: top;
              padding: 5px 10px;
              font-size: 12px;
              line-height: 16px;
      
              > strong{
                display: block;
                font-size: 14px;
                line-height: 18px;
                color: #666;
                margin-top: 2px;

                > div{
                  width: 12px;
                  height: 12px;
                  display: inline-block;
                  vertical-align: middle;
                  clear: both;
                  margin: -3px 4px 0 0;
    
                  &.grey{
                    background: $normalColor;
                  }
    
                  &.red{
                    background: $holidayColor;
                  }
    
                  &.green{
                    background: $longColor;
                  }
                }
                
                > span{
                  font-weight: normal;
                  color: #999;
                  font-size: 11px;
                }
      
                // > span{
                //   font-weight: normal;
                //   font-size: 12px;
                //   line-height: 16px;
                // }
              }
            }
          }

          > ul.meter{
            background: #EEE;
            padding: 15px;
            @extend %anim;
            // margin-bottom: 10px;
      
            > li{
              position: relative;
              display: inline-block;
              vertical-align: top;
              height: 25px;
              color: #FFF;
              font-weight: bold;
              font-size: 10px;
              line-height: 10px;
              padding: 10px 0;
              box-sizing: border-box;
              // overflow: hidden;
              @extend %anim;
      
              > span{
                position: absolute;
                margin: auto;
                text-align: center;
                top: 50%;
                transform: translate(0, -50%);
              }
      
              &:nth-child(1){
                background: $normalColor;
      
                > span{
                  left: 10px;
                }
              }
      
              &:nth-child(2){
                background: $longColor;
                z-index: 1;
      
                > span{
                  // left: 0;
                  // right: 0;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background: rgba($longColor, .75);
                  padding: 2px;
                }
              }
      
              &:nth-child(3){
                background: $holidayColor;
      
                > span{
                  right: 10px;
                }
              }
            }
      
            &.inline{
              padding: 10px;
      
              > li{
                height: 20px;
                padding: 5px 0;
      
                &:nth-child(1){
                  > span{
                    left: 5px;
                  }
                }
        
                &:nth-child(3){
                  > span{
                    right: 5px;
                  }
                }
              }
            }
          }
        }
      }
  
      > ul.day{
        margin: .5px -.5px -.5px -.5px;
        
        > li{
          display: inline-block;
          vertical-align: top;
          width: 14.28%;
          padding: .5px;
          box-sizing: border-box;
          @extend %anim;
          
          &.weekend,
          &.holiday{
            > div{
              > div{
                background: $holidayColor;
              }
            }
          }

          &.sandwich{
            > div{
              > div{
                background: $longColor;
              }
            }
          }
  
          &.today{
            > div{
              > div{
                background: $todayColor;
              }
            }
  
            &.weekend,
            &.holiday{
              > div{
                > div{
                  box-shadow: inset 0 0 0 10px $holidayColor;
                }
              }
            }

            &.sandwich{
              > div{
                > div{
                  box-shadow: inset 0 0 0 10px $longColor;
                }
              }
            }
          }
  
          > div{
            position: relative;
            height: 0;
            padding: 50% 0;
            background: #EEE;
  
            > div{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: $normalColor;
              color: #FFF;
              padding: 20px;
              font-size: 18px;
              line-height: 22px;
              cursor: pointer;
              z-index: 1;
              @extend %anim;
  
              &:hover{
                transform: scale(1.1, 1.1);
                box-shadow: 0 20px 20px -10px rgba(0,0,0,.25);
                z-index: 2;
              }
  
              > ul{
                pointer-events: none;

                > li{
                  &:nth-child(1){
                    font-weight: 100;
                    font-size: 48px;
                    line-height: 48px;
                  }

                  &:nth-child(2){
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;
                  }

                  &:nth-child(3){
                    // font-weight: bold;
                    padding-top: 2px;
                    font-size: 16px;
                    line-height: 20px;

                    > span{
                      display: none;
                      padding-top: 2px;
                      font-size: 12px;
                      line-height: 16px;
                    }
                  }
                }
              }

              > span{
                position: absolute;
                top: -10px;
                right: -10px;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom: 10px solid #FFF;
                // background: #FFF;
                bottom: auto;
                transform: rotate(45deg);
                @extend %anim;
              }
              
              > div{
                pointer-events: none;
                position: absolute;
                bottom: 20px;

                > ul{
                  margin: -1px 0;

                  > li{
                    padding: 1px 0;
                    font-size: 12px;
                    line-height: 16px;

                    &:nth-child(1),
                    &:nth-child(2){
                      display: none;
                    }
                  }
                }

                &.quarter{
                  top: 20px;
                  bottom: auto;
                  right: 20px;
                  font-size: 12px;
                  line-height: 16px;
                }

                &.value{
                  left: 20px;
                }

                &.percent{
                  right: 20px;
                  font-weight: bold;
                  text-align: right;
                }
              }
            }
          }

          &.big{
            width: 100%;

            > div{
              padding: 200px 20px;
              margin: 20px 0;

              > div{
                &:hover{
                  transform: scale(1, 1);
                }

                > ul{
                  > li{
                    &:nth-child(1){
                      font-size: 82px;
                      line-height: 82px;
                    }
  
                    &:nth-child(2){
                      font-size: 16px;
                      line-height: 20px;
                    }
  
                    &:nth-child(3){
                      font-size: 24px;
                      line-height: 28px;

                      > span{
                        display: block;
                      }
                    }
                  }
                }

                > div{
                  &.quarter{
                    font-size: 18px;
                    line-height: 22px;
                  }

                  > ul{
                    > li{
                      font-size: 16px;
                      line-height: 20px;

                      &:nth-child(1),
                      &:nth-child(2){
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
  
        &.inline{
          > li{
            &.today{
              &.weekend,
              &.holiday{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 5px $holidayColor;
                  }
                }
              }
  
              &.sandwich{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 5px $longColor;
                  }
                }
              }
            }

            > div{
              > div{
                &:hover{
                  transform: scale(1.3, 1.3);
                  box-shadow: 0 10px 10px -5px rgba(0,0,0,.25);
                }

                > span{
                  top: -5px;
                  right: -5px;
                  border: 5px solid transparent;
                  border-bottom: 5px solid #FFF;
                }

                > div{
                  display: none;
                }
                
                > ul{
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                  left: 0;
                  right: 0;

                  > li{
                    text-align: center;

                    &:nth-child(1){
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 10px;
                      line-height: 14px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }
              }
            }

            &.big{
              width: 100% !important;

              &.today{
                &.weekend,
                &.holiday{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $holidayColor;
                    }
                  }
                }
    
                &.sandwich{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $longColor;
                    }
                  }
                }
              }

              > div{
                > div{
                  &:hover{
                    transform: scale(1, 1);
                  }

                  > span{
                    top: -10px;
                    right: -10px;
                    border: 10px solid transparent;
                    border-bottom: 10px solid #FFF;
                  }

                  > div{
                    display: block;
                  }

                  > ul{
                    position: static;
                    transform: translate(0, 0);

                    > li{
                      text-align: left;

                      &:nth-child(1){
                        font-size: 82px;
                        line-height: 82px;
                      }
    
                      &:nth-child(2){
                        font-size: 16px;
                        line-height: 20px;
                      }
    
                      &:nth-child(3){
                        display: block;
                        font-size: 20px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 50%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7){
          width: 25%;
        }
      }

      &.more{
        > li{
          &:nth-child(1){
            width: 50%;
          }

          &:nth-child(2),
          &:nth-child(3){
            width: 25%;
          }
          
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6){
            width: 33.33%;
          }
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  ul.calendar{
    > li{
      > div{
        > div{
          > .head{
            &.sticky{
              top: 120px;
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              > div{
                > div{
                  > ul{
                    > li{
                      &:nth-child(1){
                        font-size: 10px;
                        line-height: 12px;
                      }
                      
                      &:nth-child(2){
                        font-size: 8px;
                        line-height: 10px;
                      }
                    }
                  }
                }
              }
            }
          }

          > li{
            &.today{
              &.weekend,
              &.holiday{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 5px $holidayColor;
                  }
                }
              }
  
              &.sandwich{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 5px $longColor;
                  }
                }
              }
            }

            > div{
              > div{
                padding: 15px;

                > ul{
                  > li{
                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  &.quarter{
                    right: 15px;
                    top: 15px;
                  }

                  &.value,
                  &.percent{
                    display: none;
                  }
                }
              }
            }

            &.big{
              &.today{
                &.weekend,
                &.holiday{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $holidayColor;
                    }
                  }
                }
    
                &.sandwich{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $longColor;
                    }
                  }
                }
              }

              > div{
                > div{
                  padding: 20px;

                  > ul{
                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    &.quarter{
                      right: 20px;
                      top: 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
  
                    &.value,
                    &.percent{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          > a{
            > svg{
              width: 113px;
            }
          }

          .today{
            font-size: 12px;
            margin: 0 0 0 123px;
          }
        }
      }
    }
  }

  ul.calendar{
    > li{
      &.year{
        > div{
          font-size: 120px;
          line-height: 124px;
        }

        > ul.insight{
          margin: -5px;
          
          > li{
            padding: 5px;
          }
        }
      }

      > div{
        > div{
          > div{
            &:first-child{
              padding-bottom: 0 !important;
            }
          }

          > .head{
            &.sticky{
              top: 120px;
            }

            > ul.insight{
              position: static;
              text-align: left;
              padding-bottom: 10px;
              margin: -2.5px -5px;
              
              > li{
                padding: 2.5px 5px;
              }
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              > div{
                padding: 200% 0;

                > div{
                  padding: 0;

                  > ul{
                    display: none;
                  }
                }
              }
            }
          }

          > li{
            &.today{
              &.weekend,
              &.holiday{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 5px $holidayColor;
                  }
                }
              }
  
              &.sandwich{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 5px $longColor;
                  }
                }
              }
            }
            
            > div{
              > div{
                padding: 10px;

                > ul{
                  > li{
                    &:nth-child(1){
                      font-size: 32px;
                      line-height: 32px;
                      // font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 12px;
                      line-height: 16px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  &.quarter{
                    right: 10px;
                    top: 10px;
                    font-size: 11px;
                    line-height: 15px;
                  }

                  &.value,
                  &.percent{
                    display: none;
                  }
                }
              }
            }

            &.big{
              &.today{
                &.weekend,
                &.holiday{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $holidayColor;
                    }
                  }
                }
    
                &.sandwich{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $longColor;
                    }
                  }
                }
              }

              > div{
                padding: 150px 20px;

                > div{
                  padding: 20px;

                  > ul{
                    display: block;

                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    &.quarter{
                      right: 20px;
                      top: 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
  
                    &.value,
                    &.percent{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 50%;

          > a{
            > svg{
              width: 113px;
            }
          }

          .today{
            font-size: 12px;
            margin: 0 0 0 123px;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7){
          width: 25%;
        }
      }
      
      &.more{
        > li{
          &:nth-child(1){
            width: 50%;
          }

          &:nth-child(2),
          &:nth-child(3){
            width: 25%;
          }
          
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6){
            width: 33.33%;
          }
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }

  ul.calendar{
    > li{
      &.year{
        > div{
          font-size: 120px;
          line-height: 124px;
        }

        > ul.insight{
          margin: -5px;
          
          > li{
            padding: 5px;
          }
        }
      }

      > div{
        > div{
          > div{
            &:first-child{
              padding-bottom: 0 !important;
            }
          }

          > .head{
            &.sticky{
              position: static;
              padding: 0;
            }

            > ul.insight{
              position: static;
              text-align: left;
              padding-bottom: 10px;
              margin: -2.5px -5px;
              
              > li{
                padding: 2.5px 5px;
              }
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              > div{
                padding: 200% 0;

                > div{
                  padding: 0;

                  > ul{
                    display: none;
                  }
                }
              }
            }
          }

          > li{
            &.today{
              &.weekend,
              &.holiday{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 3px $holidayColor;
                  }
                }
              }

              &.sandwich{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 3px $longColor;
                  }
                }
              }
            }

            > div{
              > div{
                padding: 10px;

                > ul{
                  > li{
                    &:nth-child(1){
                      font-size: 32px;
                      line-height: 32px;
                      // font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 12px;
                      line-height: 16px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  &.quarter{
                    right: 10px;
                    top: 10px;
                    font-size: 11px;
                    line-height: 15px;
                  }

                  &.value,
                  &.percent{
                    display: none;
                  }
                }
              }
            }

            &.big{
              &.today{
                &.weekend,
                &.holiday{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $holidayColor;
                    }
                  }
                }
    
                &.sandwich{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $longColor;
                    }
                  }
                }
              }

              > div{
                padding: 150px 20px;

                > div{
                  padding: 20px;

                  > ul{
                    display: block;

                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    &.quarter{
                      right: 20px;
                      top: 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
  
                    &.value,
                    &.percent{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer{
    padding: 20px 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }

  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;

          > a{
            > svg{
              width: 113px;
            }
          }

          .today{
            font-size: 12px;
            margin: 0 0 0 123px;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7){
          width: 50%;
        }
      }

      &.more{
        > li{
          &:nth-child(1){
            width: 100%;
          }

          &:nth-child(2),
          &:nth-child(3){
            width: 50%;
          }
          
          &:nth-child(4),
          &:nth-child(5){
            width: 50%;
          }

          &:nth-child(6){
            width: 100%;
          }
        }
      }
    }
  }

  .pad-top{
    height: 220px;
  }

  ul.calendar{
    > li{
      &.year{
        > div{
          font-size: 120px;
          line-height: 124px;
        }

        > ul.insight{
          margin: -5px;
          
          > li{
            padding: 5px;
          }
        }
      }

      > div{
        > div{
          > div{
            &:first-child{
              padding-bottom: 0 !important;
            }
          }

          > .head{
            &.sticky{
              position: static;
              padding: 0;
            }

            > ul.insight{
              position: static;
              text-align: left;
              padding-bottom: 10px;
              margin: -2.5px -5px;
              overflow: auto;
              
              > li{
                padding: 2.5px 5px;
                float: left;
    
                &:nth-child(4){
                  clear: both;
                }
              }
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              > div{
                padding: 200% 0;

                > div{
                  padding: 0;

                  > ul{
                    display: none;
                  }
                }
              }
            }
          }

          > li{
            &.today{
              &.weekend,
              &.holiday{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 3px $holidayColor;
                  }
                }
              }
  
              &.sandwich{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 3px $longColor;
                  }
                }
              }
            }

            > div{
              > div{
                padding: 5px 7px;

                > ul{
                  > li{
                    &:nth-child(1){
                      font-size: 16px;
                      line-height: 20px;
                      font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 10px;
                      line-height: 10px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  display: none;
                }
              }
            }

            &.big{
              &.today{
                &.weekend,
                &.holiday{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $holidayColor;
                    }
                  }
                }
    
                &.sandwich{
                  > div{
                    > div{
                      box-shadow: inset 0 0 0 10px $longColor;
                    }
                  }
                }
              }

              > div{
                padding: 50% 0;

                > div{
                  padding: 20px;

                  > ul{
                    display: block;

                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer{
    padding: 0 20px 20px 20px;
  }
}

// @media screen and (min-width: 1025px) and (max-width: 1600px){}

// @media screen and (min-width: 768px) and (max-width: 1024px){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

// @media screen and (max-width: 767px){}

// @media screen and (max-width: 767px) and (orientation: landscape){}

// @media screen and (max-width: 767px) and (orientation: portrait){}